<template>
    <div>
        <top-view></top-view>
        <div class="backTop">
        </div>
        <div style="background-color: #F4F4F4;">
            <ul class="fuwu">
                <li>
                    <img src="../../assets/image/haiwai/jingchengfuwu.png">
                    <div>
                        <p>Committed service</p>
                        <span>Professional team to serve the whole life cycle of enterprises</span>
                    </div>
                </li>
                <li>
                    <img src="../../assets/image/haiwai/huligongying.png">
                    <div>
                        <p>Mutually beneficial and 
                            win-win</p>
                        <span>Long-term co-operation and shared dividends</span>
                    </div>
                </li>
                <li>
                    <img src="../../assets/image/haiwai/yetaigongxiang.png">
                    <div>
                        <p>Business sharing</p>
                        <span>Cross-border synergy for 
                            building ambitions</span>
                    </div>
                </li>
            </ul>
        </div>
        <div class="personal">
            <p><img src="../../assets/image/left.png">&nbsp;Cooperation objectives&nbsp;<img src="../../assets/image/right.png"></p>
            <ul>
                <li>
                    <img src="../../assets/image/haiwai/mubiao1.png" style="width: 100%;height: 250px;">
                    <p>01.</p>
                    <span>The whole industry chain business matching, focusing on the sharing and integration of resources through in-depth strategic co-operation, hand in hand, win-win business opportunities</span>
                </li>
                <li>
                    <img src="../../assets/image/haiwai/mubiao2.png" style="width: 100%;height: 250px;">
                    <p>02.</p>
                    <span>Expanding multi-industry market areas, bringing new growth points, enhancing brand influence, setting industry benchmarks with craftsmanship quality, and creating service models</span>
                </li>
                <li>
                    <img src="../../assets/image/haiwai/mubiao3.png" style="width: 100%;height: 250px;">
                    <p>03.</p>
                    <span>Solve problems such as operation and management bottlenecks and team turnover, and help companies reach their business goals (revenue, cost, profit, customer volume, efficiency)</span>
                </li>
            </ul>
        </div>
        <div class="working">
            <p><img src="../../assets/image/left.png">&nbsp;Why work with us&nbsp;<img src="../../assets/image/right.png"></p>
            <div>
                <ul>
                    <li>
                        <p>Strong Enterprise</p>
                        <span>Strength</span>
                    </li>
                    <li>
                        <p>Abundant market</p>
                        <span>resources</span>
                    </li>
                    <li>
                        <p>Global Services</p>
                        <span>Local Experts</span>
                    </li>
                </ul>
                <img src="../../assets/image/haiwai/heCenter.png">
                <ul>
                    <li>
                        <p>Mature team</p>
                        <span>support</span>
                    </li>
                    <li>
                        <p>Innovation drive</p>
                        <span>and R&D strength</span>
                    </li>
                    <li>
                        <p>Excellent service</p>
                        <span>system</span>
                    </li>
                </ul>
            </div>

        </div>
        <div class="flow">
            <p><img src="../../assets/image/left.png">&nbsp;Cooperation Process&nbsp;<img src="../../assets/image/right.png"></p>
            <ul>
                <li>
                    <p>Preliminary 
                        acquaintance</p>
                </li>
                <li style="width: 30px;margin-top: 100px;"><img src="../../assets/image/haiwai/jiantou.png"></li>
                <li>
                    <p>Reach a 
                        collaboration</p>
                </li>
                <li style="width: 30px;;margin-top: 100px;"><img src="../../assets/image/haiwai/jiantou.png"></li>
                <li>
                    <p>Agreed 
                        programmes</p>
                </li>
                <li style="width: 30px;;margin-top: 100px;"><img src="../../assets/image/haiwai/jiantou.png"></li>
                <li>
                    <p>Guiding operational 
                        implementation</p>
                </li>
            </ul>
        </div>
        <div class="service">
            <p><img src="../../assets/image/haiwai/whiteD.png">&nbsp;Support Team&nbsp;<img
                    src="../../assets/image/haiwai/whiteD.png"></p>
            <ul :key="refresh">
                <li v-for="(item, index) in list" :key="index" @mouseenter="mouseenter(index)" @mouseleave="mouseleave">
                    <img  :src="imgurl + item.img">
                    <div >
                        <p>{{ item.num }}<span>members</span></p>
                        <span>{{ item.name }}</span>
                    </div>
                    <span v-html="item.content"></span>
                </li>
            </ul>
        </div>
        <div class="advantages">
            <p><img src="../../assets/image/left.png">&nbsp;Contact us&nbsp;<img src="../../assets/image/right.png"></p>
            <ul>
                <li v-for="item in dataList" :key="item.contactId">
                    <p class="title">{{ item.deptName }}</p>
                    <div class="content1" v-if="item.contactUser">
                        <div></div>
                        <p>{{ item.contactUser }}：{{ item.contactPhone }}</p>
                    </div>
                    <div class="content2">
                        <div></div>
                        <p>{{ item.contactAddress }}</p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="orange">
            Looking forward to co-operation and suggestions from you
        </div>

        <footer-view></footer-view>
        <side-bar class="fixed"></side-bar>
    </div>
</template>

<script>
import topView from '@/components/en/topView.vue'
import footerView from '@/components/en/footerView.vue'
import SideBar from '@/components/en/sideBar.vue'
import axios from 'axios';

export default {
    components: { footerView, SideBar, topView },
    data() {
        return {
            infoShow: false,
            dataList: [],
            list: [{
                num: 26,
                name: 'Consultancy Team',
                content: 'Market-wide consultancy support, <br> up-to-date information at your fingertips',
                img: require('../../assets/image/haiwai/woshou1.png')
            }, {
                num: 132,
                name: 'Operational Team',
                content: 'Q&A counselling and timely solutions',
                img: require('../../assets/image/haiwai/woshou2.png')
            }, {
                num: 568,
                name: 'Delivery team',
                content: 'High standard delivery service to <br> enhance customer experience',
                img: require('../../assets/image/haiwai/woshou3.png')
            }],
            imgurl: '',
            num: 0,
            refresh: 0
        }
    },
    created() {
        this.getList()
        // this.$store.state.componentKey += 1
        this.website()
        // this.imgurl = this.$store.state.imgurl
    },
    methods: {
        getList() {
            axios.get('/api/pcsystem/TckgContact/list',{params:{language:'1'}}).then((res) => {
                if (res.status == 200) {
                    this.dataList = res.data.rows
                    // console.log(this.dataList);
                }
            }).catch()
        },
        //网站访问信息a
        website() {
            axios.post('/api/pcsystem/TckgSoftLogin', { userType: '商业合伙' }).then((res) => {
                console.log(res);
            }).catch()
        },
        mouseenter(index) {
            let num = this.num
            if(num === 0) {
                let obj = this.list[1]
                this.list[1] = this.list[index]
                this.list[index] = obj
                this.refresh += 1
                this.num += 1
            }
            // console.log(this.list);
        },
        mouseleave() {
            this.num = 0
        }
    }

}
</script>

<style lang="scss" scoped>
.fixed {
    position: fixed;
    top: 150px;
    right: 5px;
}

.backTop {
    position: relative;
    width: 100%;
    height: 26vw;
    min-width: 1200px;
    min-height: 310px;
    background-image: url("../../assets/image/hehuoEBack.png");
    background-size: 100% 100%;
}

.fuwu {
    position: relative;
    display: flex;
    justify-content: space-around;
    margin: -50px auto 0;
    padding: 40px 30px;
    width: 1140px;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    li {
        display: flex;
        align-items: center;

        img {
            width: 50px;
            height: 50px;
        }

        div {
            margin-left: 15px;
            width: 240px;

            p {
                margin-bottom: 5px;
                font-size: 18px;
                font-weight: 600;
            }

            span {
                font-size: 16px;
                color: #4B4B4B;
            }
        }
    }
}

.kuaijiQ {
    margin: 60px auto;
    display: flex;
    justify-content: space-around;
    width: 1160px;

    img {
        border-radius: 0 !important;
    }

    div {
        margin-right: 20px;

        >p {
            margin-bottom: 50px;
            font-size: 18px;
            font-weight: 600;
        }

        .kuaijiQC {
            margin: 20px 0;
            font-size: 16px;
            font-weight: 400;
        }

        ul {
            margin-top: 50px;
            display: flex;
            justify-content: space-around;
            box-shadow: 0 0 10px 0 #F4F4F4;

            li {
                padding: 20px 30px;
                text-align: center;
                // border-right: 1px solid black;

                span {
                    display: block;
                    font-size: 14px;
                    color: #949494;
                }
            }
        }
    }

}

.kunnan {
    display: flex;
    justify-content: space-around;
    margin: 50px auto 30px;
    width: 1160px;

    li {
        position: relative;
        width: 25%;
        text-align: center;
        // overflow: hidden;

        img {
            width: 100%;
            height: 300px;
        }

        p {
            // margin-top: 20px;
            position: absolute;
            left: 6px;
            bottom: 15px;
            padding: 10px;
            width: 89%;
            text-align: center;
            color: #fff;
            background-color: rgba(0, 0, 0, 0.5);

            span {
                display: block;

                &:first-child {
                    font-size: 18px;
                }

                &:last-child {
                    font-size: 14px;
                }
            }
        }
    }
}

.canIdo {
    // position: relative;
    padding: 30px;
    background-color: #F4F4F4;
    // background-image: url('../assets/image/qidai/60f36944c6bf951d8579d623d7e627ac62513e922dccf6-6Npbt3_fw1200.png');

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    div {
        position: relative;
        display: flex;
        margin: 50px auto 30px;
        width: 1160px;

        img {
            margin: auto;
            // position: absolute;
            width: 400px;
            height: 400px;
            z-index: 9999;
        }

        ul {
            position: absolute;
            // display: flex;
            // justify-content: space-around;
            margin: 50px auto 30px;
            width: 28%;
            z-index: 0;

            li {
                // position: relative;
                margin-bottom: 20px;
                padding: 15px 20px 5px;
                // width: 10%;
                height: 80px;
                text-align: start;
                border: 2px solid #EA5514;
                background-color: #fff;
                border-radius: 10px;

                // overflow: hidden;
                p {
                    color: #727272;

                }

                span {
                    display: block;

                    &:first-child {
                        margin-bottom: 10px;
                        padding: 3px 10px;
                        width: 30%;
                        text-align: center;
                        background-color: #EA5514;
                        font-size: 18px;
                        border-radius: 30px;
                        color: #fff;
                    }

                    &:last-child {
                        font-size: 14px;
                    }
                }
            }

            &:first-child {
                top: -10%;
                left: 10%;

                li {
                    &:first-child {
                        border-radius: 0;
                        border-bottom-left-radius: 50px;
                        border-top-right-radius: 50px;
                    }

                    &:last-child {
                        border-radius: 0;
                        border-top-left-radius: 50px;
                        border-bottom-right-radius: 50px;
                    }
                }
            }

            &:last-child {
                top: -10%;
                left: 63%;

                li {
                    text-align: end;

                    span {

                        // margin-left: 150px;
                        &:first-child {
                            margin-left: 180px;
                        }
                    }

                    &:first-child {
                        border-radius: 0;
                        border-bottom-right-radius: 50px;
                        border-top-left-radius: 50px;
                    }

                    &:last-child {
                        border-radius: 0;
                        border-top-right-radius: 50px;
                        border-bottom-left-radius: 50px;
                    }
                }

            }
        }
    }



}

.service {
    padding: 50px 0 90px;
    min-width: 1200px;
    height: 535px;
    background-image: url('../../assets/image/haiwai/woshouO.png');
    background-size: 100% 100%;
    color: #fff;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                transform: rotate(180deg);
            }
        }
    }

    ul {
        margin: 70px auto 30px;
        display: flex;
        justify-content: space-around;
        width: 1160px;
        height: 375px;

        li {
            padding-bottom: 10px;
            width: 30%;
            height: 375px;
            background-color: #fff;
            text-align: center;
            border-radius: 10px;
            z-index: 999 !important;

            img {
                width: 100%;
                height: 75%;
                z-index: 0 !important;
            }

            div {
                margin: 0 auto 3px;
                padding: 5px;
                width: 90%;
                border-bottom: 1px solid #cacaca;

                p {
                    font-size: 20px;
                    color: #EB6225;
                    font-weight: 600;
                }

                span {
                    font-size: 14px;
                    font-weight: 500;
                    color: #333333;
                }
            }

            >span {
                display: inline-block;
                padding-bottom: 10px;
                font-size: 12px;
                color: #999999;
            }

            &:nth-child(2) {
                position: relative;
                top: -30px;
                height: 430px;
                width: 33%;
                // .image {
                //     background-image: url('../assets/image/haiwai/woshou2.png');
                // }
            }
        }
    }

    button {
        margin-top: 30px;
        margin-left: 48%;
        padding: 5px 15px;
        font-size: 20px;
        font-weight: 500;
        color: #fff;
        background-color: #FE8F3F;
        border: 0;
        border-radius: 20px;
    }

}

.personal {
    padding: 50px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    ul {
        margin: 50px auto 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 1160px;

        li {
            width: 32%;
            background-color: #fff;
            border-radius: 6px;

            >p {
                margin: 10px 20px;
                padding: 10px 0;
                width: 30px;
                font-size: 20px;
                font-weight: 700;
                color: #EB5E21;
                border-bottom: 3px solid #EB5E21;
            }

            span {
                display: block;
                margin: 0 20px 30px;
                // padding: 0 10px;
                font-size: 16px;
                color: #5F5F5F;
            }

            &:hover {
                // margin-top: -5px;
                position: relative;
                top: -10px;
            }

        }
    }

    button {
        margin-top: 20px;
        margin-left: 48%;
        padding: 2px 8px;
        color: #9B9B9B;
        border: 1px solid#9B9B9B;
    }
}

.working {
    padding: 30px 0 50px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    >div {
        margin: 70px auto 10px;
        display: flex;
        justify-content: space-around;
        width: 1200px;

        ul {
            margin-top: -25px;
            width: 30%;

            li {
                margin-top: 70px;
                padding: 5px;
                // width: 50%;
                width: 220px;
                height: 60px;
                color: #fff;

                p {
                    margin-left: 60px;
                    margin-top: 9px;
                }

                span {
                    display: block;
                    margin-left: 60px;
                    font-size: 16px;
                }
            }

            &:first-child {
                li {
                    margin-left: 60px;

                    &:first-child {
                        margin-top: 100px;
                        background-image: url('../../assets/image/haiwai/he1.png');
                        background-size: 100% 100%;
                    }

                    &:nth-child(2) {
                        background-image: url('../../assets/image/haiwai/he2.png');
                        background-size: 100% 100%;
                    }

                    &:last-child {
                        background-image: url('../../assets/image/haiwai/he3.png');
                        background-size: 100% 100%;
                    }
                }
            }

            &:last-child {
                li {
                    text-align: end;
                    margin-left: 60px;

                    p {
                        margin-left: 0;
                        margin-right: 60px;
                        margin-top: 9px;
                    }

                    span {
                        margin-left: 0;
                        margin-right: 60px;
                        width: 162px;
                    }

                    &:first-child {
                        margin-top: 100px;
                        background-image: url('../../assets/image/haiwai/he4.png');
                        background-size: 100% 100%;
                    }

                    &:nth-child(2) {
                        background-image: url('../../assets/image/haiwai/he5.png');
                        background-size: 100% 100%;
                    }

                    &:last-child {
                        background-image: url('../../assets/image/haiwai/he6.png');
                        background-size: 100% 100%;
                    }
                }
            }
        }
    }

}

.flow {
    padding: 0px 0 10px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    >ul {
        margin: 80px auto 20px;
        display: flex;
        justify-content: space-around;
        width: 1160px;

        li {
            // width: 16%;
            width: 210px;
            height: 220px;

            p {
                margin: 155px auto 0;
                width: 150px;
                font-size: 18px;
                color: black;
                text-align: center;
            }

            &:first-child {
                background-image: url('../../assets/image/haiwai/hezuo1.png');
                background-size: 100% 100%;

                &:hover {
                    position: relative;
                    top: -10px;
                }
            }

            &:nth-child(3) {
                background-image: url('../../assets/image/haiwai/hezuo2.png');
                background-size: 100% 100%;

                &:hover {
                    position: relative;
                    top: -10px;
                }
            }

            &:nth-child(5) {
                background-image: url('../../assets/image/haiwai/hezuo3.png');
                background-size: 100% 100%;

                &:hover {
                    position: relative;
                    top: -10px;
                }
            }

            &:last-child {
                background-image: url('../../assets/image/haiwai/hezuo4.png');
                background-size: 100% 100%;

                &:hover {
                    position: relative;
                    top: -10px;
                }
                p{
                    width: 210px;
                }
            }

        }
    }
}

.advantages {
    padding: 60px 0 50px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    ul {
        margin: 60px auto;
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
        width: 1160px;

        li {
            margin-top: 10px;
            margin-left: 10px;
            padding: 20px 0 20px 15px;
            width: 47%;
            background-color: #fff;
            border-radius: 6px;

            // color: black;
            .title {
                margin-bottom: 10px;
                color: black;
                font-size: 18px;

            }

            .content1 {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                div {
                    width: 14px;
                    height: 14px;
                    background-image: url('../../assets/image/haiwai/telH.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }

                p {
                    margin-left: 10px;
                    font-size: 14px;
                    color: #7E7E7E;
                }
            }

            .content2 {
                display: flex;
                align-items: center;

                div {
                    width: 14px;
                    height: 16px;
                    background-image: url('../../assets/image/haiwai/weizhiH.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }

                p {
                    margin-left: 10px;
                    width: 98%;
                    font-size: 14px;
                    color: #7E7E7E;
                }
            }

            &:hover {
                background-color: #EA5514;

                // color: #fff;
                .title {
                    margin-bottom: 10px;
                    color: #fff;
                    font-size: 18px;

                }

                .content1 {

                    // display: flex;
                    // align-items: center;
                    // margin-bottom: 10px;
                    div {
                        // width: 18px;
                        // height: 20px;
                        background-image: url('../../assets/image/haiwai/telW.png');
                        // background-size: 100% 100%;
                        // background-repeat: no-repeat;
                    }

                    p {
                        // margin-left: 10px;
                        // font-size: 14px;
                        color: #fff;
                    }
                }

                .content2 {

                    // display: flex;
                    // align-items: center;
                    div {
                        // width: 18px;
                        // height: 18px;
                        background-image: url('../../assets/image/haiwai/weizhiW.png');
                        // background-size: 100% 100%;
                        // background-repeat: no-repeat;
                    }

                    p {
                        // margin-left: 10px;
                        // font-size: 14px;
                        color: #fff;
                    }
                }
            }
        }
    }

    button {
        margin-left: 48%;
        padding: 5px 15px;
        font-size: 20px;
        font-weight: 500;
        color: #fff;
        background-color: #FE8F3F;
        border: 0;
        border-radius: 20px;
    }
}

.success {
    // position: relative;
    padding: 30px 0 50px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    ul {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 30px auto;
        width: 1160px;
        // width: 50%;

        li {
            // display: flex;
            margin-bottom: 25px;
            padding: 20px 15px;
            width: 30%;
            background-color: #fff;
            border-radius: 6px;

            img {
                width: 100%;
                height: 200px;
            }

            div {
                margin-left: 10px;
                // position: relative;
                text-align: start;

                // width: 500px;
                p {
                    margin-top: 10px;
                    text-align: start;
                    font-size: 16px;
                    font-weight: 600;
                }

                span {
                    display: block;
                    margin-top: 10px;
                    font-size: 14px;
                    line-height: 25px;
                    color: #AFAFAF;
                }

                button {
                    padding: 2px 5px;
                    border: 0;
                    border-radius: 2px;
                    background-color: #E6F2CC;
                    color: #A4C361;
                }
            }
        }
    }

    >button {
        margin-left: 48%;
        padding: 5px 15px;
        font-size: 20px;
        font-weight: 500;
        color: #fff;
        background-color: #FE8F3F;
        border: 0;
        border-radius: 20px;
    }
}

.offer {
    // position: relative;
    padding: 30px 0 50px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    ul {
        display: flex;
        justify-content: space-around;
        margin: 40px auto;
        width: 1160px;

        li {
            // padding: 20px;
            width: 24%;
            background-color: #fff;
            border-radius: 6px;

            p {
                margin: auto;
                padding: 40px 40px 30px;
                width: 60%;
                text-align: center;
                border-bottom: 1px dashed #E1E1E1;

                img {
                    // margin: auto;
                    width: 50px;
                    height: 50px;
                }

                span {
                    display: block;
                    margin-top: 10px;
                    font-size: 18px;
                    font-weight: 600;
                }
            }

            button {
                margin: 20px 0 20px 35%;
                padding: 5px 15px;
                color: #fff;
                background-color: #EA5514;
                border-radius: 15px;
                border: 0;
            }
        }
    }
}

.orange {
    padding: 50px 0;
    min-width: 1200px;
    width: 100%;
    background-image: url("../../assets/image/橙色背景.png");
    background-size: 100% 100%;
    text-align: center;
    color: #fff;
    font-size: 40px;

    img {
        position: relative;
        top: -10px;

        &:nth-child(2) {
            transform: rotate(180deg) !important;
        }
    }

    // line-height: 150px;
    span {
        display: block;
        margin-top: 20px;
        font-size: 16px;

        // line-height: 10px;

    }
}

.info {
    margin: auto;
    position: relative;
    width: 350px;
    border-radius: 6px;
    text-align: center;
    overflow: hidden;
    background-color: #fff;

    p {
        padding: 20px 40px 40px;
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        background-color: #EA5513;
    }

    .name {
        position: absolute;
        top: 60px;
        left: 140px;
        width: 50px;
        height: 50px;
    }

    .cheng {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 25px;
        height: 25px;

    }

    >img {
        &:last-child {
            margin: 40px 0 20px;
            width: 70%;
        }

    }
}
</style>