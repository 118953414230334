<template>
  <div class="app">
    <ul>
      <li>
        <!-- <img src="../assets/image/logoW.png" alt=""> -->
        <div>
          <p class="title">Business unit</p>
          <p @click="gotoKuaiji">Tang Cheng accounting</p>
          <p @click="gotoZizhi">Tang Cheng Qualification</p>
          <p @click="gotoQidai">Tang Cheng enterprise loan</p>
          <p @click="gotoHaiwai">Tang Cheng International</p>
          <p @click="gotoZonghe">Intellectual property</p>
        </div>
        <div>
          <p class="title">About Us</p>
          <p @click="gotoJianjie">Company's profile</p>
          <p @click="gotoDongshizhang">Message from chairman</p>
          <p @click="gotoQiyewenhua">Group company culture</p>
          <p @click="gotoJituangoucheng">Group composition</p>
          <p @click="gotoFazhanlicheng">Development history</p>
          <p @click="gotoQuanqiubujv">Global distribution </p>
        </div>
      </li>
      <li>
        <p class="title">Contact Us</p>
        <div>
          <ul>
            <li>Beijing：18600685558</li>
            <li>Hongkong：13651148758</li>
            <li>Shanghai：15001353048</li>
            <li>Guangzhou：13710179815</li>
            <li>Shenzhen：19065022582</li>
            <li>Tianjin;  18222209456</li>
            <li>Nanjing：15600082051</li>
          </ul>
          <ul>
            <li>Xi'an：15234047779</li>
            <li>Chengdu：19136165950</li>
            <li>Hangzhou：18910752234</li>
            <li>Wuhan：17702720911</li>
            <li>Shijiazhuang：15176843555</li>
            <li>Langfang：15731642111</li>
            <li>HaiNan：13651146568</li>
          </ul>
        </div>
      </li>
      <li>
        <p class="title" style="margin-left: 20px;">Follow Us</p>
        <div>
          <div>
            <img src="../../assets/image/weixinE.png" alt="">
            <p>WeChat <br> Offical account</p>
          </div>
          <div>
            <img src="../../assets/image/shipinE.png" alt="">
            <p>Follow Video offical account</p>
          </div>
          <div>
            <img src="../../assets/image/douyinE.png" alt="">
            <p>Follow tiktok</p>
          </div>
        </div>

      </li>
    </ul>
    <p>All rights reserved. Copyright &#169;{{ obj.configName }} All rights reserved. <span>{{ obj.icpBa }}</span></p>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      obj: {}
    }
  },
  methods: {
    getObj() {
      axios.get('/api/pcsystem/TckgConfig/list',{params:{language:'1'}}).then((res) => {
        if (res.status == 200) {
          this.obj = res.data.rows[0]
          // console.log(res);
        }
      }).catch()
    },
    gotoKuaiji() {
      this.$router.push({ path: '/kuaijiE', query: { key: '2' } })
    },
    gotoZonghe() {
      this.$router.push({ path: '/zongheE', query: { key: '2' } })
    },
    gotoZizhi() {
      this.$router.push({ path: '/zizhiE', query: { key: '2' } })
    },
    gotoHaiwai() {
      this.$router.push({ path: '/haiwaiE', query: { key: '3' } })
    },
    gotoQidai() {
      this.$router.push({ path: '/qidaiE', query: { key: '2' } })
    },
    goHome() {
      this.$router.push({ path: '/indexE', query: { key: '1' } })
    },
    gotoShangye() {
      this.$router.push({ path: '/shangyeE', query: { key: '4' } })
    },
    gotoZixun() {
      this.$router.push({ path: '/zixunE', query: { key: '5' } })
    },
    gotoJianjie() {
      this.$router.push({ path: '/aboutusE', query: { key: '6' } })
    },
    gotoDongshizhang() {
      this.$router.push({ path: '/dongshizhangE', query: { key: '6' } })
    },
    gotoQiyewenhua() {
      this.$router.push({ path: '/qiyewenhuaE', query: { key: '6' } })
    },
    gotoJituangoucheng() {
      this.$router.push({ path: '/jituangouchengE', query: { key: '6' } })
    },
    gotoFazhanlicheng() {
      this.$router.push({ path: '/fazhanlichengE', query: { key: '6' } })
    },
    gotoQuanqiubujv() {
      this.$router.push({ path: '/quanqiubujvE', query: { key: '6' } })
    },
  },
  created() {
    this.getObj()
  }

}
</script>

<style lang="scss" scoped>
.app {
  width: 100%;
  min-width: 1200px;
  // height: 150px;
  background-color: #351304;

  >ul {
    margin: auto;
    // padding: 70px 0 40px;
    padding: 38px 0;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    width: 1200px;

    >li {
      // width: 33%;
      // text-align: center;
      text-align: start;
      color: #fff;

      &:nth-child(1) {
        display: flex;
        justify-content: space-between;
        color: #fff;
        text-align: start;
        width: 400px;

        div {
          &:nth-child(2) {
            margin: 0 20px;
          }

          p {
            font-weight: 500;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 22px;
            // opacity: 0.6;
          }
        }
      }

      &:nth-child(2) {
        div {
          display: flex;

          ul {
            font-weight: 500;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 22px;

            &:nth-child(2) {
              margin-left: 25px;
            }
          }
        }
      }

      &:last-child {
        margin-left: 10px;
        width: 350px;

        // img{
        //   border-radius: 6px;
        // }
        >div {
          display: flex;
          justify-content: space-around;
          border: 0;

          div {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: 25px;

            &:first-child {
              margin-left: 0;
              p{
                width: 110px;
              }
            }
          }

          p {
            margin-top: 5px;
            text-align: center;
            font-size: 14px;
            // color: #AEA19B;
            color: #fff;
          }
        }


      }
      p{
        opacity: 0.6;
        margin-bottom: 8px;
      }
      li{
        margin-bottom: 8px;
        opacity: 0.6;
      }
    }
  }

  >p {
    background-color: #280C00;
    margin: 0 auto;
    padding: 34px 0;
    text-align: center;
    // color: #5C5C5C;
    color: #fff;
    //border-top: 1px solid #fff;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 20px;

    // span {
    //   color: #fff;
    // }
  }

  .title {
    margin-bottom: 18px;
    font-size: 18px !important;
    font-weight: 500;
    color: #fff;
    opacity: 1;
  }
}
</style>