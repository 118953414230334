<template>
    <div>
        <top-view></top-view>
        <div class="backTop"></div>
        <div class="kuaijiQ">
            <div>
                <p>Accounting business group</p>
                <div
                    style="margin-bottom: 40px;width: 81px;height: 4px;background: linear-gradient( 270deg, #F4F4F4 0%, #EA5514 100%);border-radius: 1px;">
                </div>
                <p class="kuaijiQC">
                    Tangcheng's pillar industrial cluster mainly serves small, medium and micro enterprises in China.
                    The business covers company registration, address hosting, bookkeeping and tax filing,
                    administrative approval, etc.
                    <br><br>
                    At present, there are more than 50,000 service enterprises in the country, with branches in 12
                    cities such as Beijing, Shanghai and Shenzhen. There are 758 professional accounting service
                    personnel in the country, including 24 certified public accountants, 15 certified tax agents and 120
                    senior accountants.
                </p>
                <ul>
                    <li>
                        <p class="num">50000<span>pluse</span></p>
                        <span>Service Business</span>
                    </li>
                    <li></li>
                    <li>
                        <p class="num">758<span>people</span></p>
                        <span>Service Personal</span>
                    </li>
                    <li></li>
                    <li>
                        <p class="num">12<span>pcs</span></p>
                        <span>City Distribution</span>
                    </li>
                </ul>
            </div>
            <img src="../../assets/image/kuaiji/kuaijishiyequn.png">
        </div>
        <div class="canIdo">
            <p><img src="../../assets/image/left.png">&nbsp;What can we do for your business &nbsp;<img
                    src="../../assets/image/right.png">
            </p>
            <!-- <span>WHAT CAN WE DO FOR YOUR BUSINESS</span> -->
            <div>
                <ul>
                    <li>
                        <p>Tax consultation</p>
                        <span>In combination with the business situation, give professional advice on tax related
                            matters</span>
                    </li>
                    <li>
                        <p>Tax payment appraisal</p>
                        <span>Through the assessment to find the deficiencies in the process of collection management,
                            strengthen the management monitoring function</span>
                    </li>
                    <li>
                        <p>Risk evaluation</p>
                        <span>Quickly diagnose the financial and tax risks of enterprises, and generate risk
                            countermeasures</span>
                    </li>
                </ul>
                <img src="../../assets/image/kuaiji/canIdo.png">
                <ul>
                    <li>
                        <p>Reduced back taxes</p>
                        <span>Solve the company's difficult problems</span>
                    </li>
                    <li>
                        <p>Tax reconsideration</p>
                        <span>If they are not satisfied with the decision of the tax authorities, they shall file a
                            reconsideration with them</span>
                    </li>
                    <li>
                        <p>Improve compliance</p>
                        <span>Solve the problem of lack of financial expertise or no financial personnel,
                            and promote professional compliance</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="service">
            <p><img src="../../assets/image/left.png">&nbsp;Service content&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <!-- <span>SERVICE CONTENT</span> -->
            <ul>
                <li>
                    <div></div>
                    <p>Company registration/deregistration</p>
                    <span>Full follow-up by specially-assigned person</span>
                </li>
                <li>
                    <div></div>
                    <p>Company Change Registration</p>
                    <span>Fast and efficient handling</span>
                </li>
                <li>
                    <div></div>
                    <p>Clear Exception</p>
                    <span>Solve the company's difficult problems</span>
                </li>
                <li>
                    <div></div>
                    <p>keeping accounts by agent</p>
                    <span>Service intimate, save time and effort</span>
                </li>
                <li>
                    <div></div>
                    <p>audit report</p>
                    <span>Specialization, scale, authority </span>
                </li>
                <li>
                    <div></div>
                    <p>Company acquisition/transfer</p>
                    <span>Signed formal agreement, safe and reliable</span>
                </li>
            </ul>
            <div style="display: flex;justify-content: center;"><button @click="infoShow = true">Click to
                    consult</button></div>
        </div>
        <div class="orange">
            Low cost to solve the problems encountered by <br>
            the current enterprise, one household one service solution
        </div>
        <div class="personal">
            <p><img src="../../assets/image/left.png">&nbsp;Senior Specialist&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <!-- <span>SENIOR EXPERTS</span> -->
            <ul>
                <li v-for="item in personalList" :key="item.personnelId">
                    <img :src="imgurl + item.personnelImg" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">{{ item.personnelName }}</span>
                        <span class="personalC">{{ item.personnelPosition }}</span>
                        <span class="personalRemark">
                            <div style="display: flex;" v-for="(children, index) in item.remark" :key="index">
                                <div
                                    style="margin-top: 4px;width: 4px;height: 4px;border-radius: 50%;border: 2px solid #fff;background-color: #EA5514;">
                                </div>
                                <div style="margin-left: 8px;width: 240px;">{{ children }}</div>
                            </div>
                        </span>
                    </p>
                </li>
            </ul>
            <button @click="isShow()" class="btn">
                <p v-if="!show">Unfold<img src="../../assets/image/kuaiji/zhankai.png"></p>
                <p v-else>Put it away<img src="../../assets/image/kuaiji/shouqi.png"></p>
            </button>
        </div>
        <div class="flow">
            <p><img src="../../assets/image/left.png">&nbsp;Service process&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <!-- <span>SERVICE FLOW</span> -->
            <ul>
                <li>
                    <img src="../../assets/image/kuaiji/jiantou.png">
                    <p>01.</p>
                    <span>Understand basic information</span>
                </li>
                <li>
                    <img src="../../assets/image/kuaiji/jiantou.png">
                    <p>02.</p>
                    <span>Enter into a contract</span>
                </li>
                <li>
                    <img src="../../assets/image/kuaiji/jiantou.png">
                    <p>03.</p>
                    <span>Match a professional accounting service team</span>
                </li>
                <li>
                    <img src="../../assets/image/kuaiji/jiantou.png">
                    <p>04.</p>
                    <span>Handle tax registration with real name Collection, bank tripartite agreement Signing of
                        agreement</span>
                </li>
                <li>
                    <img src="../../assets/image/kuaiji/jiantou.png">
                    <p>05.</p>
                    <span>Handle according to demand
                        Approve and receive invoices</span>
                </li>
                <li>
                    <img src="../../assets/image/kuaiji/jiantou.png">
                    <p>10.</p>
                    <span>
                        Provide value-added
                        services</span>
                </li>
                <li>
                    <img src="../../assets/image/kuaiji/jiantou.png">
                    <p>09.</p>
                    <span>Customer service specialist schedule Pay a return visit</span>
                </li>
                <li>
                    <img src="../../assets/image/kuaiji/jiantou.png">
                    <p>08.</p>
                    <span>Produce monthly financial statements</span>
                </li>
                <li>
                    <img src="../../assets/image/kuaiji/jiantou.png">
                    <p>07.</p>
                    <span>Provide monthly bookkeeping, tax declaration, tax payment and other services</span>
                </li>
                <li>
                    <img src="../../assets/image/kuaiji/jiantou.png">
                    <p>06.</p>
                    <span>Collect and deliver invoices and documents <br> on a monthly basis</span>
                </li>
            </ul>
        </div>
        <div class="advantages">
            <p><img src="../../assets/image/left.png">&nbsp;Advantage of service&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <!-- <span>SERVICE ADVANTAGES</span> -->
            <div>
                <img src="../../assets/image/kuaiji/woshou.png">
                <ul>
                    <li>
                        <div>01</div>
                        <p>Strong professional, rich practical experience</p>
                    </li>
                    <li>
                        <div>02</div>
                        <p>The industry covers a wide range</p>
                    </li>
                    <li>
                        <div>03</div>
                        <p>Anticipate market trends in a timely manner
                            and provide forward-looking services</p>
                    </li>
                    <li>
                        <div>04</div>
                        <p>Perfect training system, there is a
                            professional team </p>
                    </li>
                </ul>
            </div>
            <div style="display: flex;justify-content: center;"><button @click="infoShow = true">Click to
                    consult</button></div>
        </div>
        <div class="success">
            <p><img src="../../assets/image/left.png">&nbsp;Successful case&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <!-- <span>SUCCESS STORIES</span> -->
            <div>
                <ul>
                    <li v-for="item in caseList" :key="item.caseId" @mouseenter="changeContent(item)"
                        @mouseleave="recoveryContent(item)">
                        <div class="img"></div>
                        <div>
                            <p>Project:{{ item.caseTitle }}<button>Time:{{ item.caseHs }}</button></p>
                            <span v-html="item.caseTitle2"></span>
                            <!-- <button>耗时：{{ item.caseHs }}</button> -->
                        </div>
                        <div class="gengduo"></div>
                    </li>
                </ul>
                <img src="../../assets/image/kuaiji/a5d49088970fab9672c7d45a1976b659993fe925b76e0-6MpW8n.png">
            </div>
        </div>
        <div class="offer">
            <p><img src="../../assets/image/left.png">&nbsp;We also provide these services&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <!-- <span>WE ALSO OFFER THESE SERVICES</span> -->
            <ul>
                <li>
                    <p>
                        <img src="../../assets/image/kuaiji/jiangpai.png">
                        <span>Building qualification</span>
                    </p>
                    <button @click="gotoZizhi">for more information</button>
                </li>
                <li>
                    <p>
                        <img src="../../assets/image/kuaiji/qianbao.png">
                        <span>Financial service</span>
                    </p>
                    <button @click="gotoQidai">for more information</button>
                </li>
                <li>
                    <p>
                        <img src="../../assets/image/kuaiji/haiwai.png">
                        <span>Overseas business</span>
                    </p>
                    <button @click="gotoHaiwai">for more information</button>
                </li>
                <li>
                    <p>
                        <img src="../../assets/image/kuaiji/zhishi.png">
                        <span>Intellectual property</span>
                    </p>
                    <button @click="gotoZonghe">for more information</button>
                </li>
            </ul>
        </div>
        <div class="orange">
            Tang Cheng Holding Strength Guarantee
            <span style="font-size: 22px;display: inline-block;margin-top: 20px;">Low cost to solve the problems
                encountered by the current enterprise, one household one service solution</span>
        </div>

        <footer-view></footer-view>
        <side-bar class="fixed"></side-bar>
        <el-dialog title="Please fill in your personal information" :visible.sync="infoShow" width="40%" center>
            <span style="font-size: 12px;color: #A5A5A5;text-align: center;display: block;margin-top: -30px;">We will
                contact you within 30 minutes</span>

            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
                <el-form-item label="Name:" prop="name">
                    <el-input v-model="ruleForm.name" placeholder="Please enter your name"></el-input>
                </el-form-item>
                <el-form-item label="Phone Number:" prop="tel">
                    <el-input v-model="ruleForm.tel" placeholder="Please enter your phone number"></el-input>
                </el-form-item>
                <el-form-item label="Leave a message:" prop="content">
                    <el-input v-model="ruleForm.content" type="textarea" placeholder="Please enter the question you want to consult
 (no more than 300 words)" class="inputC"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm('ruleForm')">Table shortly</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import topView from '@/components/en/topView.vue'
import footerView from '@/components/en/footerView.vue'
import SideBar from '@/components/en/sideBar.vue'
import axios from 'axios';

export default {
    components: { footerView, SideBar, topView },
    data() {
        return {
            show: false,
            infoShow: false,
            personalList: [],
            params: { pageSize: 8, pageNum: 1, personnelType: "5", language: '1' },
            // imgurl: 'http://192.168.0.199:8087/api',
            imgurl: '',
            caseList: [],
            ruleForm: {
                name: '',
                tel: '',
                content: ''
            },
            rules: {
                name: [
                    { required: true, message: '请输入您的姓名', trigger: 'blur' },
                    // { min: 7, max: 11, message: '请输入正确的手机号码', trigger: 'blur' }
                ],
                tel: [
                    { required: true, message: '请输入您的电话', trigger: 'blur' },
                    { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur' }
                ],
                content: [
                    // { required: true, message: '请输入您的电话', trigger: 'blur' },
                    { min: 0, max: 300, message: '最多输入300字', trigger: 'blur' }
                ]

            },
            text: '',
        }
    },
    created() {
        this.getPersonalList()
        this.getcaseList()
        this.website()
        this.imgurl = this.$store.state.imgurl
        // this.$store.state.componentKey += 1
    },
    methods: {
        isShow() {
            this.show = !this.show
            if (!this.show) {
                this.params.pageSize = 8
                this.getPersonalList()
            } else {
                this.params.pageSize = 12
                this.getPersonalList()
            }
        },
        getPersonalList() {
            axios.get('/api/pcsystem/TckgPersonnel/list', { params: this.params }).then((res) => {
                if (res.status == 200) {
                    this.personalList = res.data.rows
                    for (let i = 0; i < this.personalList.length; i++) {
                        this.personalList[i].remark = res.data.rows[i].remark.split('。')
                    }
                    // console.log(this.personalList);
                }
            }).catch()
        },
        getcaseList() {
            axios.get('/api/pcsystem/TckgCase/list', { params: { pageNum: 1, pageSize: 3, caseType: '5', language: '1' } }).then((res) => {
                if (res.status == 200) {
                    this.caseList = res.data.rows
                    // console.log(this.caseList);
                }
            }).catch()
        },
        gotoAboutus() {
            this.$router.push('/aboutusE')
        },
        gotoQidai() {
            this.$router.push({ path: '/qidaiE', query: { key: '2' } })
        },
        gotoZonghe() {
            this.$router.push({ path: '/zongheE', query: { key: '2' } })
        },
        gotoHaiwai() {
            this.$router.push({ path: '/haiwaiE', query: { key: '3' } })
        },
        gotoZizhi() {
            this.$router.push({ path: '/zizhiE', query: { key: '2' } })
        },
        //网站访问信息
        website() {
            axios.post('/api/pcsystem/TckgSoftLogin', { userType: '会计事业群' }).then((res) => {
                console.log(res);
            }).catch()
        },
        //提交信息
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // alert('submit!');
                    this.infoShow = false
                    console.log(this.ruleForm);
                    axios.post('/api/pcsystem/TckgConsult', { params: this.ruleForm }).then((res) => {
                        // console.log(res);
                        if (res.status == 200) {
                            // console.log(111111);
                            this.$message({
                                message: '提交成功，请耐心等待',
                                type: 'success'
                            });
                        }
                    }).catch()
                } else {
                    // this.$message({
                    //     message: '提交失败，请稍后重试',
                    //     type: 'warning'
                    // });
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        changeContent(item) {
            this.text = item.caseTitle2
            item.caseTitle2 = item.caseContent
            // console.log(item);
        },
        recoveryContent(item) {
            console.log(this.text);
            item.caseTitle2 = this.text
            // console.log(item);
        }
    }

}
</script>

<style lang="scss" scoped>
.fixed {
    position: fixed;
    top: 150px;
    right: 5px;
}

.backTop {
    position: relative;
    width: 100%;
    height: 26vw;
    // height: 500px;
    // height: 450px;
    min-width: 1200px;
    min-height: 310px;
    background-image: url("../../assets/image/kuaijiEBack.png");
    background-size: 100% 100%;
}

.kuaijiQ {
    margin: 60px auto;
    display: flex;
    justify-content: space-around;
    width: 1200px;

    img {
        border-radius: 0 !important;
    }

    div {
        margin-right: 20px;

        >p {
            margin-bottom: 10px;
            font-size: 22px;
            font-weight: 600;
        }

        .kuaijiQC {
            margin: 20px 0;
            font-size: 16px;
            line-height: 25px;
            font-weight: 400;
        }

        ul {
            margin-top: 50px;
            display: flex;
            justify-content: space-evenly;
            box-shadow: 0 0 10px 0 #F4F4F4;

            li {
                padding: 20px 30px;
                text-align: center;
                // border-right: 1px solid black;

                p {
                    font-size: 30px;
                    font-weight: 600;
                    color: #8FC31F;
                }

                span {
                    font-size: 14px;
                    color: #666666;
                    font-weight: 500;
                }

                &:nth-child(2),
                &:nth-child(4) {
                    margin-top: 35px;
                    padding: 0;
                    width: 1px;
                    height: 26px;
                    // border: 1px solid #C8C4C4;
                    background-color: #C8C4C4;
                }
            }
        }
    }

}

.canIdo {
    // position: relative;
    padding: 30px 0;
    padding-top: 60px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 80px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    >div {
        margin: 50px auto;
        display: flex;
        justify-content: space-around;
        width: 1200px;

        img {
            width: 453px;
            height: 448px;
        }

        ul {
            li {
                margin-bottom: 50px;
                width: 310px;

                p {
                    margin: 0 0 10px;
                    padding: 3px 0;
                    width: 260px;
                    color: #fff;
                    text-align: center;
                    border-radius: 0px 20px 0px 20px;
                    background-color: #EA5514;
                    font-size: 20px;
                    font-weight: 500;
                }

                span {
                    font-size: 16px;
                    font-weight: 400;
                    color: #767676;
                }
            }

            &:last-child {
                text-align: end;

                li {
                    width: 300px;

                    &:first-child {
                        span {
                            display: inline-block;
                            width: 220px;
                        }
                    }

                    &:nth-child(2) {
                        margin-top: 70px;
                    }

                    &:last-child {
                        margin-top: 70px;
                    }

                    p {
                        margin-left: 40px;
                    }
                }
            }
        }
    }

}

.service {
    // position: relative;
    padding: 30px 0;
    padding-top: 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 60px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    ul {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 40px auto 20px;
        width: 1160px;

        li {
            margin-bottom: 1%;
            padding-bottom: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 32.5%;
            background-color: #fff;
            border-radius: 6px;
            cursor: pointer;

            >div {
                margin: 30px 0 10px;
                width: 70px;
                height: 80px;
                background-image: url('../../assets/image/kuaiji/zhuceO.png');
                background-size: 100% 100%;
            }

            &:nth-child(2) {
                >div {
                    width: 80px;
                    height: 80px;
                    background-image: url('../../assets/image/kuaiji/biangengO.png');
                }

                &:hover {
                    >div {
                        background-image: url('../../assets/image/kuaiji/biangengH.png');
                    }
                }
            }

            &:nth-child(3) {
                >div {
                    width: 80px;
                    height: 80px;
                    background-image: url('../../assets/image/kuaiji/yichangO.png');
                }

                &:hover {
                    >div {
                        background-image: url('../../assets/image/kuaiji/yichangH.png');
                    }
                }
            }

            &:nth-child(4) {
                >div {
                    width: 80px;
                    height: 80px;
                    background-image: url('../../assets/image/kuaiji/jizhangO.png');
                }

                &:hover {
                    >div {
                        background-image: url('../../assets/image/kuaiji/jizhangH.png');
                    }
                }
            }

            &:nth-child(5) {
                >div {
                    width: 70px;
                    height: 80px;
                    background-image: url('../../assets/image/kuaiji/shenjiO.png');
                }

                &:hover {
                    >div {
                        background-image: url('../../assets/image/kuaiji/shenjiH.png');
                    }
                }
            }

            &:nth-child(6) {
                >div {
                    width: 80px;
                    height: 80px;
                    background-image: url('../../assets/image/kuaiji/shougouO.png');
                }

                &:hover {
                    >div {
                        background-image: url('../../assets/image/kuaiji/shougouH.png');
                    }
                }
            }

            p {
                margin: 8px;
                font-size: 20px;
                font-weight: 500;
            }

            span {
                font-size: 14px;
                color: #B1B1B1;
            }

            &:hover {
                background-color: #EA5514;
                color: #fff;

                >div {
                    background-image: url('../../assets/image/kuaiji/zhuceH.png');
                }

                span {
                    color: #fff;
                }
            }
        }
    }

    button {
        margin: 75px 0;
        // margin-left: 46%;
        // margin-left: 45%;
        // min-width: 8%;
        width: 252px;
        height: 56px;
        padding: 5px 15px;
        // font-size: 26px;
        font-size: 26px;
        font-weight: 500;
        color: #fff;
        // background-color: #FE8F3F;
        background: linear-gradient(180deg, #FFB552 0%, #FF4D00 100%);
        border: 0;
        border-radius: 30px;
        text-align: center;
    }
}

.personal {
    // position: relative;
    padding: 30px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-top: 30px;
        margin-bottom: 50px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    ul {
        margin: 30px auto 0;
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
        width: 1160px;

        li {
            position: relative;
            margin-top: 1vh;
            margin-left: 10px;
            width: 24%;
            height: 340px;
            background-size: 100% 100%;
            border-radius: 6px;
            overflow: hidden;

            >p {
                margin: 0;
                position: absolute;
                bottom: 0;
                padding: 10px 13px 5px;
                width: 91%;
                min-height: 75px;
                background-color: rgba(0, 0, 0, 0.5);
                text-align: start;
                // border-radius: 0;
                color: #fff;
            }

            .personalT {
                display: block;
                font-size: 18px;
                font-weight: 500;
            }

            .personalC {
                font-size: 13px;
                font-weight: 500;
            }

            .personalRemark {
                margin-top: 5px;
                display: none;
                padding: 5px 10px;
                background-color: #EA5514;
                border-radius: 3px;
                font-size: 9px;
                line-height: 15px;
            }

            &:hover {
                .personalRemark {
                    display: block;
                }
            }
        }
    }

    button {
        margin-top: 50px;
        margin-bottom: 40px;
        margin-left: 47.5%;
        padding: 2px 8px;
        width: 130px;
        color: #979797;
        border: 1px solid#979797;
        font-size: 18px;
        border-radius: 4px;
        background-color: #F4F4F4;
    }
}

.flow {
    // position: relative;
    padding: 50px 0 100px;
    min-width: 1200px;
    background-image: url("../../assets/image/kuaiji/6458486361c124a42770f394d12bef7d526a503611913d-0S20U5_fw1200.png");

    >p {
        margin-bottom: 50px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    >ul {
        margin: 40px auto;
        // padding: 0 0 20px;
        display: flex;
        flex-wrap: wrap;
        width: 1200px;
        height: 280px;
        background-image: url("../../assets/image/kuaiji/line.png");
        background-size: 100% 90%;
        background-repeat: no-repeat;

        li {
            width: 19%;
            text-align: center;
            font-size: 16px;

            span {
                // width: 20px;
                display: block;
                margin: auto;
                // width: 150px;
                // font-size: 14px;
                line-height: 25px;
            }

            &:first-child {
                margin: 0 1%;
                width: 15%;
            }

            &:nth-child(2) {
                margin: 0 3%;
                width: 7%;
            }

            &:nth-child(4) {
                margin: 0 1%;
                width: 22%;
            }

            &:nth-child(6) {
                margin: 0 1%;
                width: 15%;
            }

            &:nth-child(7) {
                margin: 0 2% 0 1%;
                width: 14%;
            }

            &:nth-child(8) {
                width: 15%;
            }

            &:nth-child(9) {
                margin: 0 1%;
                width: 21%;
            }

            &:first-child,
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
                margin-top: -12px;
            }

            &:last-child,
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8),
            &:nth-child(9) {
                margin-top: 110px;

                img {
                    transform: rotate(180deg);
                }
            }
        }
    }

}

.advantages {
    // position: relative;
    padding: 50px 0 20px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    >div {
        display: flex;
        margin: 30px auto;
        width: 1160px;

        // justify-content: space-around;
        img {
            width: 50%;
        }

        ul {
            margin-top: 55px;
            margin-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 50%;
            height: 340px;

            li {
                display: flex;
                align-items: center;
                margin-top: 20px;
                padding: 8px 15px;
                width: 90%;
                height: 56px;
                background-color: #fff;
                border-radius: 6px;

                div {
                    width: 30px;
                    height: 30px;
                    background-image: url('../../assets/image/kuaiji/quan.png');
                    background-size: 100% 100%;
                    text-align: center;
                    line-height: 30px;
                    color: #fff;
                    font-weight: 600;
                }

                p {
                    margin-left: 20px;
                    width: 440px;
                    line-height: 20px;
                    font-size: 20px;
                }

                &:hover {
                    margin-left: -5%;
                    width: 95%;
                    background-color: #EA5514;
                    color: #fff;

                    div {
                        background-image: url('../../assets/image/kuaiji/quanH.png');
                        color: #EA5514;
                    }
                }
            }
        }
    }

    button {
        margin: 75px 0 40px;
        width: 252px;
        height: 56px;
        padding: 5px 15px;
        font-size: 26px;
        font-weight: 500;
        color: #fff;
        background: linear-gradient(180deg, #FFB552 0%, #FF4D00 100%);
        border: 0;
        border-radius: 30px;
        text-align: center;
    }
}

.success {
    // position: relative;
    padding: 0px 0 20px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    >div {
        display: flex;
        justify-content: space-around;
        // align-items: center;
        margin: 60px auto 30px;
        width: 1160px;

        // justify-content: space-around;
        img {
            width: 45%;
            height: 488px;
        }

        ul {
            // flex-grow: 1;
            // margin-top: 110px;
            // display: flex;
            // flex-direction: column;
            // justify-content: space-around;
            // margin-right: 20px;
            width: 53%;
            height: 488px;
            overflow: hidden;

            li {
                position: relative;
                display: flex;
                margin-bottom: 15px;
                padding: 20px 15px;
                // width: 100%;
                height: 23%;
                background-color: #fff;
                border-radius: 6px;

                .img {
                    margin: 0;
                    width: 20px;
                    height: 20px;
                    background-image: url('../../assets/image/kuaiji/dui.png');
                    background-size: 100% 100%;
                }

                div {
                    margin-left: 15px;
                    position: relative;
                    text-align: start;
                    cursor: pointer;
                    width: 92%;

                    >p {
                        margin-bottom: 5px;
                        text-align: start;
                        font-size: 16px;
                        font-weight: 600;
                    }

                    span {
                        // width: 100%;
                        font-size: 14px;
                        line-height: 25px;
                        // height: 75px;
                        color: #727272;
                        // display: -webkit-box;
                        // -webkit-box-orient: vertical;
                        // -webkit-line-clamp: 3;
                        // overflow: hidden;
                        // text-overflow: ellipsis;

                    }

                    button {
                        // position: absolute;
                        // top: 0;
                        // left: 150px;
                        margin-left: 40px;
                        padding: 2px 5px;
                        border: 0;
                        border-radius: 2px;
                        background-color: #FCEEE7;
                        color: #EB5E20;
                    }

                }

                .gengduo {
                    position: absolute;
                    right: 20px;
                    width: 20px;
                    height: 4px;
                    background-image: url('../../assets/image/gengduo.png');
                    background-size: 100% 100%;
                }

                &:last-child {
                    margin-bottom: 0;

                    &:hover {
                        position: relative;
                        top: -334px;
                        // top: -304px;
                    }
                }

                &:first-child {
                    &:hover {
                        position: relative;
                        top: 0;
                    }
                }

                &:nth-child(2) {
                    &:hover {
                        position: relative;
                        top: -167px;
                    }
                }

                &:hover {
                    height: 448px;
                    background-color: #EA5514;
                    color: #fff;

                    span {
                        color: #fff;
                        display: block !important;
                    }

                    .img {
                        background-image: url('../../assets/image/kuaiji/duiW.png');
                    }

                    div {
                        button {
                            background-color: #EF8050;
                            color: #fff;
                        }
                    }

                    .gengduo {
                        display: none;
                    }
                }
            }
        }
    }
}

.offer {
    // position: relative;
    padding: 30px 0 50px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    ul {
        display: flex;
        justify-content: space-around;
        margin: 60px auto 40px;
        width: 1160px;

        li {
            // padding: 20px;
            width: 24%;
            background-color: #fff;
            border-radius: 6px;
            text-align: center;

            p {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: auto;
                padding: 40px 40px 20px;
                width: 40%;
                text-align: center;
                border-bottom: 1px dashed #E1E1E1;

                img {
                    // margin: auto;
                    width: 50px;
                    height: 50px;
                }

                span {
                    display: block;
                    margin-top: 10px;
                    width: 200px;
                    font-size: 18px;
                    font-weight: 500;
                }
            }

            button {
                margin: 20px 0;
                padding: 5px 15px;
                color: #fff;
                background-color: #EA5514;
                border-radius: 15px;
                border: 0;
            }

            &:first-child {
                img {
                    width: 60px;
                    height: 60px;
                }

                p {
                    padding-top: 38px;

                    span {
                        margin-top: 5px;
                    }
                }
            }

            &:nth-child(3) {
                img {
                    width: 58px;
                    height: 58px;
                }

                p {
                    padding-top: 35px;

                    span {
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}

.orange {
    padding: 50px 0;
    width: 100%;
    min-width: 1200px;
    // min-height: 140px;
    background-image: url("../../assets/image/橙色背景.png");
    background-size: 100% 100%;
    text-align: center;
    color: #fff;
    font-size: 40px;

    // line-height: 70px;
    span {
        display: block;

        // margin-top: 25px;
        // font-size: 26px;
        &:last-child {
            // font-size: 16px;
        }

        // line-height: 10px;
        img {
            position: relative;
            top: -10px;

            // margin-top: -10px;
            &:last-child {
                transform: rotate(180deg);
            }
        }
    }

    .mini {
        margin-top: 20px;
        font-size: 22px;

        img {
            position: relative;
            top: -4px;

            // margin-top: -10px;
            &:last-child {
                transform: rotate(180deg);
            }
        }
    }
}

.info {
    margin: auto;
    position: relative;
    width: 350px;
    border-radius: 6px;
    text-align: center;
    overflow: hidden;
    background-color: #fff;

    p {
        padding: 20px 40px 40px;
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        background-color: #EA5513;
    }

    .name {
        position: absolute;
        top: 60px;
        left: 140px;
        width: 50px;
        height: 50px;
    }

    .cheng {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 25px;
        height: 25px;

    }

    >img {
        &:last-child {
            margin: 40px 0 20px;
            width: 70%;
        }

    }
}

::v-deep .el-dialog--center .el-dialog__body {
    padding-bottom: 0;
}

::v-deep .el-form {
    margin-top: 30px;
    margin-left: -20px;
}

::v-deep .el-textarea__inner {
    height: 150px !important;
}
</style>